/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    br: "br"
  }, _provideComponents(), props.components), {HrefLangManager, LinkWithQuery, LandingPageCta, SideBySide, MapInfo, ThreeColCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!LinkWithQuery) _missingMdxReference("LinkWithQuery", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/'], ['en', 'https://www.hear.com/hearing-aids/brands/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/']]
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearcom-the-best-place-to-find-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearcom-the-best-place-to-find-hearing-aids",
    "aria-label": "hearcom the best place to find hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "hear.com: the best place to find hearing aids!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the U.S., there are over 1,000 ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/"
  }, "hearing aid devices"), " on the market from the leading manufacturers. Our mission at hear.com, along with our comprehensive network of ", React.createElement(LinkWithQuery, {
    to: "/about-us/network/"
  }, "Partner Providers"), ", is to help you find your perfect hearing aid. To aid you in your search, we have put together some helpful information on the top hearing aid brands, such as Signia, ReSound, Phonak, Widex, and more. Simply click on the brand names below to learn more about the company, as well as available models and their specifications."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Are you looking for hearing aids? Would you like to learn more about the top hearing aid brands? Then contact us! Our hearing aid experts will advise you on different hearing aid options, completely free of charge. All our consultations are non-binding and unbiased, meaning our services are risk-free and tailored to your specific needs. In collaboration with a hearing aid provider in your area, we offer ", React.createElement(LinkWithQuery, {
    to: "/hearing-aids/prices/"
  }, "the best hearing aid brands at affordable prices"), "."), "\n", React.createElement(LandingPageCta, {
    copy: "Find which is best for you",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "overview-of-hearing-aid-manufacturers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overview-of-hearing-aid-manufacturers",
    "aria-label": "overview of hearing aid manufacturers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Overview of hearing aid manufacturers:"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-hearcom.png"
  }, React.createElement(LinkWithQuery, {
    to: "/hearing-aids/horizon/"
  }, "hear.com"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 2012"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Coral Gables, FL\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "The strength of Horizon IX hearing aids by hear.com come to the fore in complex hearing situations with several sound sources and speakers. Using two separate processors, significantly increases the contrast between speech and background noise. The result: Hearing aids have never sounded so natural, detailed and clear. Our hearing devices come in different types, behind the ear and invisible in canal. With Bluetooth connectivity and rechargeability features you will never miss a single moment in your life.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-signia.png"
  }, React.createElement(LinkWithQuery, {
    to: "/hearing-aids/brands/signia/"
  }, "Signia"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1964"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Piscataway, NJ\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Signia is the latest hearing aid brand, co-branded with Siemens. Signia primax builds on the successful binax platform by providing a true binaural experience that redefines ease of listening.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-widex.png"
  }, React.createElement(LinkWithQuery, {
    to: "/hearing-aids/brands/widex/"
  }, "Widex"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1956"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Lynge, Denmark\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Widex believes that feeding the auditory system with authentic, detailed sound can enhance speech clarity, promote social interactions, and improve quality of life. Widex innovates hearing aids to respect all sounds, tailor the sound experience, and focus on what matters. The world’s first fully digital hearing aid is a testament to Widex’s spirit of innovation.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-oticon.jpg"
  }, React.createElement(LinkWithQuery, {
    to: "/hearing-aids/brands/oticon/"
  }, "Oticon"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1904"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Smørum, Denmark\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Oticon is built on a heritage and purpose of People First to reinforce the power of technology to improve not only the quality of hearing, but the overall quality of life. For more than 115 years, Oticon has brought life-changing hearing aids to market that exceeds the needs and expectations of people with hearing loss.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-resound.png"
  }, React.createElement(LinkWithQuery, {
    to: "/hearing-aids/brands/resound/"
  }, "ReSound"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1943"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Ballerup, Denmark\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Using innovative hearing solutions, ReSound guides people of all ages back into the world of sound. Since 1943, this Danish company has been developing high-quality hearing devices that allow their wearers to forget they have hearing loss.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-phonak.png"
  }, React.createElement(LinkWithQuery, {
    to: "/hearing-aids/brands/phonak/"
  }, "Phonak"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1947"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Stäfa, Switzerland\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "From its inception, Phonak has been setting the standard for developing innovative, contemporary hearing aids – something this Swiss manufacturer has kept up for nearly 70 years. The customer is always the main focus of hearing aids developed by Phonak.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-unitron.png"
  }, React.createElement(LinkWithQuery, {
    to: "/hearing-aids/brands/unitron/"
  }, "Unitron"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1964"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Kitchener, ON, Canada\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "The ability to hear greatly affects one’s quality of life. Unitron’s state-of-the-art hearing aids help restore a higher quality of life for those with hearing loss. Unitron aims to provide uncompromising speech comprehension and natural hearing.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-starkey.png"
  }, React.createElement(LinkWithQuery, {
    to: "/hearing-aids/brands/starkey/"
  }, "Starkey"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1964"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Eden Prairie, MN\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Starkey Hearing is much more than the hearing aids they produce. They are in the business of connecting people and changing lives. They believe that being able to hear the world and the people around us is as essential to the human experience as breathing.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-rexton.png"
  }, React.createElement(LinkWithQuery, {
    to: "/hearing-aids/brands/rexton/"
  }, "Rexton"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Est. 1955"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Head office: Plymouth, MN\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Rexton has been helping people improve their hearing for more than 50 years. Rexton’s hearing aids distinguish themselves for their high quality and usability combined with compact design. In cooperation with audiologists, Rexton develops high-quality hearing aids tailored to the needs of the customer.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-audio-service.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(LinkWithQuery, {
    to: "/hearing-aids/brands/audio-service/"
  }, "Discover Audio Service"), "\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Our sense of hearing is a basic human need that affects everything we do. We rely on our hearing to communicate with loved ones, and even more importantly, to warn us of oncoming danger. The declared aim of the Westphalian hearing aid manufacturer, Audio Service, is to help people with hearing loss enjoy life at the right volume. Audio Service’s focus is on customer service, entrepreneurship, and innovation. With these values in mind, Audio Service is committed to providing high-quality hearing aids for people experiencing signs of hearing loss.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-beltone.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(LinkWithQuery, {
    to: "/hearing-aids/brands/beltone/"
  }, "Discover Beltone"), "\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", "Sam and Faye Rosen founded Beltone in Chicago in 1940. They engineered hearing aids in order to help a friend who had hearing loss. In 1950, Beltone introduced a behind-the-ear model hearing aid. The first Beltone in-the-ear hearing aid came out in the 1960’s.")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hear-what-our-customers-have-to-say-about-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hear-what-our-customers-have-to-say-about-hearcom",
    "aria-label": "hear what our customers have to say about hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hear what our customers have to say about hear.com"), "\n", React.createElement("div", {
    className: "js-trustpilot-widget",
    "data-locale": "en-US",
    "data-template-id": "54ad5defc6454f065c28af8b",
    "data-businessunit-id": "5601900f0000ff00058398fb",
    "data-style-height": "240px",
    "data-style-width": "100%",
    "data-theme": "light",
    "data-stars": "4,5",
    "data-review-languages": "en"
  }, React.createElement("a", {
    href: "https://www.trustpilot.com/review/hear.com",
    target: "_blank",
    rel: "noopener"
  }, "Trustpilot")), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "lets-choose-a-hearing-aid-brand-together",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#lets-choose-a-hearing-aid-brand-together",
    "aria-label": "lets choose a hearing aid brand together permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Let’s choose a hearing aid brand, together."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This was just an overview of some of the latest hearing aids by the leading manufacturers. For more information about hearing aids, pricing, and financing options, give us a call or complete our online questionnaire to speak with one of our hearing aid experts."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We at hear.com, along with our network of 2,000+ Partner Providers, are here to guide you on the path to better hearing. We carry all the latest hearing aids from all the top brands, so rest assured, you’ll receive a high quality product at an affordable price. Not only that, you’ll enjoy a 45-day risk-free trial period to wear, test, and fall in love with your new devices and improved quality of life."), "\n", React.createElement(MapInfo), "\n", React.createElement(LandingPageCta, {
    copy: "Find which is best for you",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
